import type { PermissionAction } from '@models';

export default {
  base: [
    {
      action: 'listRegistries',
      target: '*',
    },
    {
      action: 'getRegistry',
      target: '*',
    },
    {
      action: 'createRegistry',
      target: '*',
    },
    {
      action: 'updateRegistry',
      target: '*',
    },
    {
      action: 'deleteRegistry',
      target: '*',
    },
    {
      action: 'createImage',
      target: '*',
    },
    {
      action: 'getActions',
      target: '*',
    },
  ],
  dashboard: [
    {
      action: 'listImages',
      target: '*',
    },
    {
      action: 'listAlerts',
      target: '*',
    },
    {
      action: 'getAlert',
      target: '*',
    },
    {
      action: 'updateAlert',
      target: '*',
    },
    {
      action: 'viewReports',
      target: '*',
    },
    {
      action: 'createSubscription',
      target: '*',
    },
    {
      action: 'createRepository',
      target: '*',
    },
  ],
  applications: [
    {
      action: 'listImages',
      target: '*',
    },
    {
      action: 'listSources',
      target: '*',
    },
    {
      action: 'getApplication',
      target: '*',
    },
  ],
  'artifacts/image': [
    {
      action: 'getPolicy',
      target: '*',
    },
    {
      action: 'updatePolicy',
      target: '*',
    },
    {
      action: 'createRepository',
      target: '*',
    },
    {
      action: 'listImages',
      target: '*',
    },
    {
      action: 'getImage',
      target: '*',
    },
    {
      action: 'deleteImage',
      target: '*',
    },
    {
      action: 'createImage',
      target: '*',
    },
    {
      action: 'getImageEvaluation',
      target: '*',
    },
    {
      action: 'listSubscriptions',
      target: '*',
    },
    {
      action: 'createSubscription',
      target: '*',
    },
    {
      action: 'updateSubscription',
      target: '*',
    },
    {
      action: 'createNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'getNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'listNotificationEndpointConfigurations',
      target: '*',
    },
    {
      action: 'listNotificationEndpoints',
      target: '*',
    },
    {
      action: 'getActions',
      target: '*',
    },
    {
      action: 'addAction',
      target: '*',
    },
    {
      action: 'listRuntimeInventories',
      target: '*',
    },
    {
      action: 'getRuntimeInventory',
      target: '*',
    },
    {
      action: 'listAlerts',
      target: '*',
    },
    {
      action: 'getAlert',
      target: '*',
    },
    {
      action: 'viewReports',
      target: '*',
    },
  ],
  'artifacts/source': [
    {
      action: 'getPolicy',
      target: '*',
    },
    {
      action: 'updatePolicy',
      target: '*',
    },
    {
      action: 'listImages',
      target: '*',
    },
    {
      action: 'getImage',
      target: '*',
    },
    {
      action: 'listSources',
      target: '*',
    },
    {
      action: 'getSource',
      target: '*',
    },
    {
      action: 'deleteImage',
      target: '*',
    },
    {
      action: 'createImage',
      target: '*',
    },
    {
      action: 'getImageEvaluation',
      target: '*',
    },
    {
      action: 'listSubscriptions',
      target: '*',
    },
    {
      action: 'createSubscription',
      target: '*',
    },
    {
      action: 'updateSubscription',
      target: '*',
    },
    {
      action: 'createNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'getNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'listNotificationEndpointConfigurations',
      target: '*',
    },
    {
      action: 'listNotificationEndpoints',
      target: '*',
    },
    {
      action: 'getActions',
      target: '*',
    },
    {
      action: 'addAction',
      target: '*',
    },
    {
      action: 'listRuntimeInventories',
      target: '*',
    },
    {
      action: 'getRuntimeInventory',
      target: '*',
    },
    {
      action: 'listAlerts',
      target: '*',
    },
    {
      action: 'getAlert',
      target: '*',
    },
    {
      action: 'viewReports',
      target: '*',
    },
  ],
  policy: [
    {
      action: 'getImage',
      target: '*',
    },
    {
      action: 'getImageEvaluation',
      target: '*',
    },
    {
      action: 'listPolicies',
      target: '*',
    },
    {
      action: 'getPolicy',
      target: '*',
    },
    {
      action: 'createPolicy',
      target: '*',
    },
    {
      action: 'updatePolicy',
      target: '*',
    },
    {
      action: 'deletePolicy',
      target: '*',
    },
  ],
  reports: [
    {
      action: 'listImages',
      target: '*',
    },
    {
      action: 'createScheduledQuery',
      target: '*',
    },
    {
      action: 'updateScheduledQuery',
      target: '*',
    },
    {
      action: 'executeScheduledQuery',
      target: '*',
    },
    {
      action: 'deleteScheduledQuery',
      target: '*',
    },
    {
      action: 'deleteScheduledQueryResult',
      target: '*',
    },
    {
      action: 'viewReports',
      target: '*',
    },
  ],
  events: [
    {
      action: 'listEvents',
      target: '*',
    },
    {
      action: 'deleteEvents',
      target: '*',
    },
    {
      action: 'createNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'deleteNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'updateNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'getNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'listNotificationEndpointConfigurations',
      target: '*',
    },
    {
      action: 'listNotificationEndpoints',
      target: '*',
    },
  ],
  'inventory/kubernetes': [
    {
      action: 'listImages',
      target: '*',
    },
    {
      action: 'createImage',
      target: '*',
    },
    {
      action: 'createSubscription',
      target: '*',
    },
    {
      action: 'updateSubscription',
      target: '*',
    },
    {
      action: 'listRuntimeInventories',
      target: '*',
    },
    {
      action: 'viewReports',
      target: '*',
    },
  ],
  notifications: [
    {
      action: 'listEvents',
      target: '*',
    },
    {
      action: 'deleteEvents',
      target: '*',
    },
    {
      action: 'createNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'deleteNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'updateNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'getNotificationEndpointConfiguration',
      target: '*',
    },
    {
      action: 'listNotificationEndpointConfigurations',
      target: '*',
    },
    {
      action: 'listNotificationEndpoints',
      target: '*',
    },
  ],
  system: [
    {
      action: 'getAccount',
      target: '*',
    },
    {
      action: 'listUsers',
      target: '*',
    },
    {
      action: 'createUser',
      target: '*',
    },
    {
      action: 'updateUser',
      target: '*',
    },
    {
      action: 'deleteUser',
      target: '*',
    },
    {
      action: 'listRoles',
      target: '*',
    },
    {
      action: 'getRole',
      target: '*',
    },
    {
      action: 'listRoleMembers',
      target: '*',
    },
    {
      action: 'createRoleMember',
      target: '*',
    },
    {
      action: 'deleteRoleMember',
      target: '*',
    },
    {
      action: 'listApiKeys',
      target: '*',
    },
    {
      action: 'createApiKey',
      target: '*',
    },
    {
      action: 'updateApiKey',
      target: '*',
    },
    {
      action: 'listServices',
      target: '*',
    },
    {
      action: 'listFeeds',
      target: '*',
    },
  ],
  useradmin: [
    {
      action: 'listUsers',
      target: '*',
    },
    {
      action: 'createUser',
      target: '*',
    },
    {
      action: 'updateUser',
      target: '*',
    },
    {
      action: 'deleteUser',
      target: '*',
    },
    {
      action: 'listRoles',
      target: '*',
    },
    {
      action: 'getRole',
      target: '*',
    },
    {
      action: 'listRoleMembers',
      target: '*',
    },
    {
      action: 'createRoleMember',
      target: '*',
    },
    {
      action: 'deleteRoleMember',
      target: '*',
    },
    {
      action: 'listApiKeys',
      target: '*',
    },
    {
      action: 'createApiKey',
      target: '*',
    },
    {
      action: 'updateApiKey',
      target: '*',
    },
  ],
} satisfies Record<string, PermissionAction[]>;
