import type { DurationInputArg1, DurationInputArg2 } from 'moment-timezone';
import moment from 'moment-timezone';

export function updateTag(
  tagName: string,
  keyName: string,
  keyValue: string,
  attrName: string,
  attrValue: string,
) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name: string, content: string) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property: string, content: string) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel: string, href: string) {
  updateTag('link', 'rel', rel, 'href', href);
}

export function setCaretToEndOfInput(el: HTMLElement | null) {
  if (el instanceof Node) {
    const thisEl = el as HTMLInputElement;
    thisEl.focus();
    if (thisEl.setSelectionRange) {
      const len = thisEl.value.length * 2;
      setTimeout(() => {
        thisEl.setSelectionRange(len, len);
      }, 1);
    }
    if (typeof thisEl.selectionStart === 'number') {
      thisEl.selectionEnd = thisEl.value.length;
      thisEl.selectionStart = thisEl.value.length;
    } else if (
      'createTextRange' in thisEl &&
      typeof thisEl.createTextRange === 'function'
    ) {
      thisEl.focus();
      const range = thisEl.createTextRange();
      range.collapse(false);
      range.select();
    }
    thisEl.blur(); // Webkit wake-up hack
    thisEl.focus();
  }
  return el;
}

// This utility function ensures correct sorting on alphanumeric strings such as
// vulnerability identifiers or feed group names.
//
// Example cases:
//
// CVE-2019-9999 should come before CVE-2019-10001
// alpine:3.10 should come after alpine:3.2
export function sortAlphanumeric(
  aVal?: string | number | null,
  bVal?: string | number | null,
) {
  let a = aVal;
  let b = bVal;
  // Force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  // Force any string values to lowercase
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;
  // Utilize localeCompare if both values are strings
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b, undefined, { numeric: true });
  }
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) return 1;
  if (a < b) return -1;
  // Returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
}

// This utility function converts a cron expression UTC <-> Local
//
// Setting: 'utc' || 'local'
//
// Example case with -7:00 UTC offset:
//
// '0 6 * * 1' -> '0 23 * * 0'
export function convertCronTo(
  setting: 'utc' | 'local',
  cron: string | undefined,
) {
  if (!cron) {
    return '';
  }

  const USER_TIMEZONE = moment.tz.guess();
  // Parse user's timezone for UTC offset
  const utcOffset = moment.tz(USER_TIMEZONE).utcOffset();
  // Round down as the 30 - 45 minute zone diffs will be added to the text
  const offsetInHours = Math.floor(utcOffset / 60);
  let dayChange = 0;
  // Direction dictates whether we're adding/subtracting offset
  // by multiplying offset by either a positive or negative 1
  let direction = 1;
  // Converting to UTC means we need to flip the polarity
  if (setting === 'utc') direction = -1;

  const [, hours, daysOfMonth, , daysOfWeek] = cron.split(' ');
  // Go through each hour and apply offset
  const newHour = hours
    .split(',')
    .map(item => {
      let afterOffset = +item + offsetInHours * direction;
      if (afterOffset < 0) {
        afterOffset += 24;
        dayChange = -1;
      } else if (afterOffset >= 24) {
        afterOffset -= 24;
        dayChange = 1;
      }
      return afterOffset;
    })
    .join(',');
  let newDayOfWeek = daysOfWeek;
  let newDayOfMonth = daysOfMonth;
  if (dayChange) {
    // Determine cycle
    let cycle;
    if (daysOfWeek === '0,1,2,3,4,5,6') cycle = 'daily';
    if (!cycle && daysOfWeek && daysOfWeek !== '*') {
      cycle = 'weekly';
    }
    if (!cycle) cycle = 'monthly';

    // Minor utility function to correctly offset weekday values
    const offsetDay = (day: string) => {
      let afterOffset = +day + dayChange;
      if (afterOffset < 0) afterOffset += 7;
      if (afterOffset >= 7) afterOffset -= 7;
      return afterOffset;
    };

    if (cycle === 'weekly') {
      // Go through each day of the week and add/take dayChange value (+1/-1)
      newDayOfWeek = daysOfWeek.split(',').map(offsetDay).sort().join(',');
    } else if (cycle === 'monthly') {
      if (daysOfMonth !== '*') {
        const specificDays = daysOfMonth.split(',');
        newDayOfMonth = specificDays
          .map(item => {
            let afterOffset = +item + dayChange;
            if (afterOffset < 1) afterOffset = 31;
            if (afterOffset > 31) afterOffset = 1;
            return afterOffset;
          })
          .sort()
          .join(',');
      }
    }
  }
  return `0 ${newHour} ${newDayOfMonth} * ${newDayOfWeek}`;
}

// This utility function consumes a local cron expression and outputs a
// human-readable string
//
// Example case:
//
// '0 6 ? * 1, 5' -> 'Weekly on Monday and Friday at 6AM'
export function getCronSummary(cron: string) {
  if (!cron) {
    return '';
  }
  const USER_TIMEZONE = moment.tz.guess();
  const [, hours, daysOfMonth, , daysOfWeek] = cron.split(' ');
  const summary: string[] = [];

  // Parse user's timezone for UTC offset
  const utcOffset = moment.tz(USER_TIMEZONE).utcOffset();
  // Account for 30 - 45 minute zones
  const remainder = utcOffset % 60;

  // Correctly separate values with the power of grammar
  const commaSeparateValues = (val: string, list: string[], indexShift = 0) => {
    let valStr = '';
    const valArr = val.split(',').map(num => Number(num) + indexShift);

    if (valArr.length === 1) valStr = list[valArr[0]];
    else if (valArr.length === 2) {
      valStr = valArr.map(item => list[item]).join(' and ');
    } else if (valArr.length > 2) {
      const lastVal = valArr.pop();
      valStr = valArr.map(item => list[item]).join(', ');
      valStr += `, and ${list[lastVal as number]}`;
    }

    return valStr;
  };
  // Generate array of hours -> 12AM - 11PM
  const hoursList = Array.from(Array(24), (e, i) => {
    let num = i;
    if (i === 0) num = 12;
    if (i > 12) num -= 12;
    let suffix = 'AM';
    if (i >= 12) suffix = 'PM';
    return `${num}${remainder ? `:${remainder}` : ''}${suffix}`;
  });
  // Simple array for days of the week
  const daysOfWeekList = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  // Generates array of days of the month -> 1st - 31st
  const daysOfMonthList = Array.from(Array(31), (e, i) => {
    const num = i + 1;
    let suffix = 'th';
    if (num === 1 || num === 21 || num === 31) suffix = 'st';
    if (num === 2 || num === 22) suffix = 'nd';
    if (num === 3 || num === 23) suffix = 'rd';
    return `${num}${suffix}`;
  });

  // Determine cycle
  let cycle = '';
  if (daysOfWeek === '0,1,2,3,4,5,6') cycle = 'Daily';
  if (!cycle && daysOfWeek && daysOfWeek !== '*') {
    cycle = 'Weekly';
  }
  if (!cycle) cycle = 'Monthly';
  summary.push(cycle);
  // Determine 'On'
  if (cycle !== 'Daily') {
    let on = '';
    if (daysOfWeek !== '*') {
      on = `on ${commaSeparateValues(daysOfWeek, daysOfWeekList)}`;
    } else if (daysOfMonth !== '*') {
      on = `on the ${commaSeparateValues(daysOfMonth, daysOfMonthList, -1)}`;
    }
    summary.push(on);
  }
  // Determine 'At'
  const at = `at ${commaSeparateValues(hours, hoursList)}`;
  summary.push(at);
  return summary.join(' ');
}

// Callback for a reduce operation! Gets rid of invalid alert filters
export function toValidFilters(
  obj: Record<string, string>,
  currFilter: [
    string,
    { value: string; duration: [DurationInputArg1, DurationInputArg2] },
  ],
): Record<string, string> {
  const [key, info] = currFilter;
  const { value, duration } = info;
  const isValid = value && typeof value === 'string' && value.trim().length;
  const newObj = obj;
  if (isValid) newObj[key] = value;
  // In the case of 'Time', recreate timestamp based on given duration
  if (
    key === 'created_after' &&
    duration &&
    duration instanceof Array &&
    duration.length === 2
  ) {
    const [num, unit] = duration;
    newObj[key] = moment.utc().subtract(num, unit).format();
  }
  return newObj;
}

/**
 * Programmatically hide *all* instances of the Semantic UI React
 * [modal dialog](https://react.semantic-ui.com/modules/modal/) located in the
 * DOM, _except for the last one found in the collection_—so, the most recent
 * addition.
 *
 * We have to do this using a JS utility because the modal component has an
 * inaccessible inline style attribute on the outer element wrapper:
 * ```
 * style="display: flex !important;"
 * ```
 * If you're curious as to what the precise opposite of helpful looks like, it
 * looks like that.
 *
 * So, the purpose of this utility is to prevent multiple dialogs and their
 * assets visually occluding each other, which includes the associated lightbox—
 * this layering leads to the overall background getting successively darker,
 * bleaker, emptier, turning and turning in the widening gyre, the falcon cannot
 * hear the falconer, and so forth.
 *
 * You can provide overrides to the update timeout, and also to the class
 * selector string used to locate the dialog component, but this utility is
 * intended to only be used with Semantic modal dialogs, so do this at your own
 * risk...
 * @param {any} conf Configuration object
 * @param {number} conf.timeout Timeout in milliseconds, defaults to `0`
 * @param {string} conf.classNames Selector string used to locate the dialog, defaults to `"ui page modals dimmer transition visible active"`
 * @param {function} conf.onComplete
 * @returns {boolean|number} Timeout identifier, or `false`
 */
export function hideAllExceptTopModal(
  conf: {
    classNames?: string;
    setModalHideDisplayValueTo?: string;
    setModalShowDisplayValueTo?: string;
    setModalDisplayPriorityTo?: string;
    timeout?: number;
    onComplete?: () => void;
  } = {},
) {
  let timeoutId: ReturnType<typeof setTimeout> | false = false;

  if (process.env.BROWSER) {
    const {
      classNames = 'ui page modals dimmer transition visible active',
      setModalHideDisplayValueTo = 'none',
      setModalShowDisplayValueTo = 'flex',
      setModalDisplayPriorityTo = 'important',
    } = conf;

    let { timeout = 0 } = conf;

    timeout =
      typeof timeout === 'number' && !(timeout % 1 !== 0) && timeout > 0
        ? timeout
        : 0;

    if (typeof classNames === 'string' && classNames.trim()) {
      const changeDisplayStyles = () => {
        const nodeColl = document.getElementsByClassName(
          classNames.trim(),
        ) as HTMLCollectionOf<HTMLElement>;
        if (nodeColl.length) {
          Array.from(nodeColl).forEach((item, idx, list) => {
            if (list.length > 1 && idx !== list.length - 1 && item?.style) {
              item.style.setProperty(
                'display',
                typeof setModalHideDisplayValueTo === 'string'
                  ? setModalHideDisplayValueTo.trim()
                  : 'none',

                typeof setModalDisplayPriorityTo === 'string'
                  ? setModalDisplayPriorityTo.trim()
                  : 'important',
              );
            } else {
              item.style.setProperty(
                'display',
                typeof setModalShowDisplayValueTo === 'string'
                  ? setModalShowDisplayValueTo.trim()
                  : 'flex',

                typeof setModalDisplayPriorityTo === 'string'
                  ? setModalDisplayPriorityTo.trim()
                  : 'important',
              );
            }
          });
        }
        if (typeof conf.onComplete === 'function') {
          conf.onComplete();
        }
      };

      timeoutId = setTimeout(changeDisplayStyles, timeout);
    }
  }

  return timeoutId;
}

/**
 * This utility function converts a rem value to a pixel value.
 * @param remValue
 * @returns {number|number}
 */
export function remToPx(remValue: number) {
  return process.env.BROWSER
    ? remValue *
        parseFloat(getComputedStyle(window.document.documentElement).fontSize)
    : 0;
}
