import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { FeatureList, FeatureName } from '@models';

export const EntitlementsContext = createContext<Partial<FeatureList>>({});

interface EntitlementsProviderProps {
  entitlements: Partial<FeatureList>;
}

export const EntitlementsProvider: FC<EntitlementsProviderProps> = ({
  children = null,
  entitlements = {},
}) => (
  <EntitlementsContext.Provider value={entitlements}>
    {children}
  </EntitlementsContext.Provider>
);

const useEntitlementsContext = () => useContext(EntitlementsContext);

/**
 * Returns a boolean indicating whether a given entitlement is enabled
 */
export const useEntitlement = (name: FeatureName) => {
  const entitlements = useEntitlementsContext();

  return name in entitlements && entitlements[name];
};

export interface EntitlementProps {
  name: FeatureName;
  then: ReactNode;
  else?: ReactNode;
}

/**
 * Conditionally render components based on the state of a given entitlement
 */
const Entitlements: FC<EntitlementProps> = ({
  name,
  then: thenExpr,
  else: elseExpr = null,
}) => {
  const hasFeature = useEntitlement(name);

  return <>{hasFeature ? thenExpr : elseExpr}</>;
};

export default Entitlements;
