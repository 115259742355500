import PropTypes from 'prop-types';

import type { Permissions, PermissionAction } from '@models';
import type { AuthAccountState } from '@redux/reducers/reducer-auth';

// Property validation schema for the data passed to the any RBAC utilities
// exported from this module
const schema = {
  getUserPerms: {
    account: PropTypes.oneOfType([
      PropTypes.shape({
        isAdmin: PropTypes.bool,
        accountname: PropTypes.string,
        rbac: PropTypes.arrayOf(
          PropTypes.shape({
            account: PropTypes.string,
            permissions: PropTypes.arrayOf(
              PropTypes.shape({
                action: PropTypes.string,
                target: PropTypes.string,
                from: PropTypes.arrayOf(PropTypes.string),
              }),
            ),
            roles: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string,
                immutable: PropTypes.bool,
                description: PropTypes.string,
              }),
            ),
          }),
        ),
      }),
      PropTypes.bool,
    ]),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        target: PropTypes.string,
      }),
    ),
  },
};

interface RBACProps {
  account?: AuthAccountState | false;
  actions?: PermissionAction[];
}

type FnReturnValue<B> = B extends true
  ? { action: keyof Permissions; allowed: boolean }[]
  : Partial<Permissions>;

/**
 * Utility function that carries out the permissions reconciliation operation
 * Returns a different data structure depending on the value of the `list`
 */
function fn<B extends boolean | undefined>(
  props: RBACProps,
  list: B | undefined,
): FnReturnValue<B> | Error;
function fn(
  props: RBACProps,
  list: boolean | undefined,
): FnReturnValue<boolean> | Error {
  // Validate the account object and actions array passed to the function. The
  // provided data must have type integrity for the Promise to resolve, and
  // will throw an exception if any of the values are incorrect.
  //
  // If the `list` parameter is `true`, the operation will return an array of
  // objects that each provide the name of an action and whether the operation
  // is allowed or not.
  //
  // If the list parameter is a falsy value (or omitted), the operation will return
  // a single object, where each key is the name of an action and each corresponding
  // value a Boolean indicating whether the operation is allowed or not.
  let valid = true;

  PropTypes.checkPropTypes(
    schema.getUserPerms,
    props,
    'prop',
    'RBAC User Permissions Resolution',
    () => {
      // For reasons best known to the React dev team, this callback only gets
      // invoked when a type error is encountered. Fortunately, the utility is
      // synchronous with the outer scope, so we can set the flag to `false`
      // and the next condition will pick it up.
      valid = false;
    },
  );

  try {
    // Errors at this point are serious and should halt the app
    const permissionErr = new Error(
      'Invalid data passed to RBAC Permissions Processor',
    );

    if (valid) {
      const { account, actions } = props;

      if (!account) {
        return list ? ([] satisfies FnReturnValue<true>) : {};
      }

      const { isAdmin, rbac, accountname, context } = account;

      // Get the role data for this account from the user's RBAC account list,
      // or return `false` if no corresponding account entry is found
      const rbacAcct =
        Array.isArray(rbac) && rbac.length
          ? rbac.find(
              item =>
                (isAdmin && item.account === accountname) ||
                item.account === context?.accountname,
            )
          : false;

      // If role data for the current account was found, get the permissions
      // list, or return `false` if the list is empty or unavailable
      const rbacAcctPerms =
        rbacAcct &&
        rbacAcct.permissions instanceof Array &&
        rbacAcct.permissions.length
          ? rbacAcct.permissions
          : false;

      // Reduce the action array down to an object, where each key is the action
      // name and the value is whether the RBAC permission list for the standard
      // user has the corresponding action.
      //
      // If no matching action is found but the user is an admin user, the value
      // is set to `true`. Otherwise, the value is set to `false`.
      const permissions: Partial<Permissions> =
        actions?.reduce((perms, actionItem) => {
          perms[actionItem.action] =
            account && !isAdmin && rbacAcctPerms
              ? rbacAcctPerms.some(
                  perm =>
                    (perm.action === actionItem.action &&
                      perm.target === actionItem.target) ||
                    perm.action === '*',
                )
              : account && isAdmin;
          return perms;
        }, {} as Partial<Permissions>) || {};

      // The permissions can be returned as objects inside a list if required
      // (useful for array test operations)
      if (list) {
        const permissionsList: {
          action: keyof Permissions;
          allowed: boolean;
        }[] = (Object.keys(permissions) as (keyof Permissions)[]).map(
          (item: keyof Permissions) => ({
            action: item,
            allowed: permissions?.[item] || false,
          }),
        );

        return permissionsList;
      }

      return permissions;
    }

    return permissionErr;
  } catch (err) {
    return err as Error;
  }
}

// Async wrapper for the permissions check operation
export const getUserPerms = <B extends boolean | undefined>(
  props: RBACProps,
  list?: B,
): Promise<FnReturnValue<B>> =>
  new Promise((resolve, reject) => {
    const permissions = fn(props, list);
    if (permissions instanceof Error) {
      reject(permissions);
    } else {
      resolve(permissions);
    }
  });

// Sync wrapper for the permissions check operation
export const getUserPermsSync = <B extends boolean | undefined>(
  props: RBACProps,
  list?: B,
): FnReturnValue<B> | Error => fn(props, list);
